import { clearLocalOverrides, getEnvConfig, setJwEnv, setLocalOverride } from './util';

if (import.meta.env.APP_JW_ENV === 'dev' || import.meta.env.APP_JW_ENV === 'stg') {
  (window as unknown as { Dash: unknown }).Dash = {
    /**
     * Switch to another env for the JW Platform
     *
     * Pass `null` to reset to default
     */
    setEnv(env: 'dev' | 'stg' | 'prd' | null) {
      const newEnv = env === null ? (import.meta.env.APP_JW_ENV as never) : env;
      setJwEnv(newEnv);
      console.info('Updated JW environment to %o.', newEnv);
    },

    /**
     * Override the URL to the API Gateway
     *
     * Pass `null` to reset to default
     */
    setApiUrl(url: string | null) {
      const apiUrl = url === null ? getEnvConfig().apiUrl : url;
      setLocalOverride({
        apiUrl,
      });
      console.info('Updated API URL to %o.', apiUrl);
    },

    /**
     * Clear all local config overrides.
     */
    clearLocalOverrides() {
      clearLocalOverrides();
      console.info('Cleared all local configuration overrides.');
    },
  };
}
