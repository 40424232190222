import devFile from '../../.env.dev?raw';
import prdFile from '../../.env.prd?raw';
import stgFile from '../../.env.stg?raw';

type ConfigKey = keyof { [K in keyof ImportMetaEnv as Extract<K, K extends `APP_${string}` ? K : never>]: never };

function parseConfig(txt: string): Record<ConfigKey, string> {
  const vars: Record<string, string> = {};

  txt.split('\n').forEach((line) => {
    line = line.trim();
    if (!line || line.startsWith('#')) {
      return;
    }

    if (line.includes('=')) {
      const [key, ...valueParts] = line.split('=');
      vars[key] = valueParts.join('=');
    }
  });

  return vars;
}

export const envConfigs = {
  dev: parseConfig(devFile),
  stg: parseConfig(stgFile),
  prd: parseConfig(prdFile),
};
