/**
 * Before we start anything, we need to set up the correct configuration (in Companion Lib). Most of these values are
 * taken from the `.env` file in the root dir.
 *
 * Then we try to assert the user's session as soon as possible.
 */

import { setConfig } from '@jwplayer/companion';
import { applyConfig, config } from '@jwplayer/companion-gateway';
import { deleteCookie, setCookie } from '@jwplayer/fe-common/module/cookies';
import { LocalStorage } from '@jwplayer/fe-common/module/local-storage';
import { LOCATION_IS_LOCALHOST } from '../config/constants/runtime';

// Initialize Dashboard's runtime config
import { ConfigStore } from '/config';

// Import Dash debug util (will not work in PRD)
import '../debug/global-dash-util';

// Force jw dashboard (jwd) prefix for all localstorage
LocalStorage.setGlobalPrefix('jwd_');

// We changed cookies to be on `jwplayer.com`. We'll automatically delete any
// auth cookies on the specific domain (e.g. `dashboard.jwplayer.com`) so that
// they don't take precedence over the cookies on `jwplayer.com`
// TODO: DASH-3851
function clearOldCookies() {
  deleteCookie(config.get().auth.cookies.token, { Path: '/' });
  deleteCookie(config.get().auth.cookies.authFromAdmin, { Path: '/' });
  deleteCookie(config.get().auth.cookies.mfa.identifier, { Path: '/' });
}

async function initConfig() {
  // Initialize companion-lib's runtime config
  const configStoreState = ConfigStore.getCurrentState();
  setConfig({
    API_URL: configStoreState.apiUrl,
    CONTENT_SERVER: configStoreState.contentServerUrl,

    // Companion v2 takes care of this now
    AUTH_COOKIE_IDENTIFIER: undefined,

    // Companion v2 takes care of this now
    AUTH_AUTO_REFRESH: false,

    LOG_LEVELS: configStoreState.companionLogLevels,
    NRT_SERVER: configStoreState.nrtServerUrl,

    // Don't let Companion v1 store the last used site token in a cookie.
    // The Dashboard does all of this now!
    CURRENT_SITE_TOKEN_COOKIE_IDENTIFIER: undefined,
  });

  // Initialize companion v2 runtime config
  await applyConfig(import.meta.env.APP_JW_ENV);
}

async function initPrBuild() {
  if (import.meta.env.APP_JW_ENV !== 'dev') {
    return;
  }

  // If user is requesting a PR build through the special `/_PR/{number}` path
  const prNumber = parseInt(window.location.pathname.split('/').pop() || '', 10);
  if (window.location.pathname.startsWith('/_PR/') && !isNaN(prNumber)) {
    // ⚠️ Before setting PR_NUMBER cookie, check if a file exists at that path
    await fetch(`https://dev-dashboard.jwplayer.com/react/PR/${prNumber}/index.html`)
      .then((res) => {
        if (res.ok) {
          // Set the PR_NUMBER cookie and reload to redirect the user to the PR build.
          // The dashboard-server will read this cookie and route to the index.html files
          // for that specific PR build.
          setCookie('PR_NUMBER', `${prNumber}`, {
            Path: '/',
          });
          window.location.pathname = '/';
        }
      })
      .catch(() => {
        console.warn(`Could not resolve PR build: ${prNumber}`);
      });
  }

  // If this build is a PR build
  if (import.meta.env.APP_PR_NUMBER) {
    console.warn(
      `You are viewing a PR build for https://github.com/jwplayer/dashboard/pull/${import.meta.env.APP_PR_NUMBER}`,
      '\n\n',
      'To switch back to the normal dev environment, remove the PR_NUMBER cookie.',
    );
  }
}

async function rewriteOldPaths() {
  const MAPPING = [
    {
      old: ['account/forgot', 'account/reset'],
      new: '/reset-password',
      usp: (params) => {
        const usp = new URLSearchParams();
        usp.set('token', params.get('requestToken') ?? '');
        return usp;
      },
    },
    {
      old: ['account/create'],
      new: '/sign-up',
      usp: (params) => {
        const usp = new URLSearchParams();
        usp.set('token', params.get('inviteToken') ?? '');
        return usp;
      },
    },
  ] as const satisfies readonly {
    old: readonly string[];
    new: string;
    usp: (params: URLSearchParams) => URLSearchParams;
  }[];

  function parseQueryParams(pathname: string): URLSearchParams {
    const usp = new URLSearchParams();

    const searchString = pathname.split('?').pop();
    if (!searchString) {
      return usp;
    }

    searchString.split('&').forEach((str) => {
      const [key, ...rest] = str.split('=');
      const val = rest.join('=');
      usp.set(key, val);
    });

    return usp;
  }

  const currentUsp = parseQueryParams(window.location.href);
  const currentUrl = new URL(window.location.href);

  MAPPING.forEach((mapping) => {
    const matches = mapping.old.some((v) => window.location.hash.includes(v));
    if (!matches) {
      return;
    }

    // Transform current url
    currentUrl.hash = '';
    currentUrl.pathname = mapping.new;
    currentUrl.search = mapping.usp(currentUsp).toString();

    window.history.pushState(null, '', currentUrl.pathname + currentUrl.search);
  });
}

(async () => {
  await initConfig();
  await initPrBuild();
  await rewriteOldPaths();

  if (!LOCATION_IS_LOCALHOST) {
    clearOldCookies();
  }

  // Import second stage asynchronously
  import('./stage-2').then(() => {
    // no-op
  });
})();
