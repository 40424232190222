import { setConfig as setCompanionConfig } from '@jwplayer/companion';
import { useEffect, useMemo, useState } from 'react';

import { ConfigStore, DashboardConfig } from '/config';

import { envConfigs } from './get-configs';

type Env = 'dev' | 'stg' | 'prd';

const ENV_CONFIGS = {
  dev: {
    apiUrl: envConfigs.dev.APP_API_URL,
    apiPlatformUrl: envConfigs.dev.APP_API_PLATFORM_URL,
    contentServerUrl: envConfigs.dev.APP_CONTENT_SERVER,
    shareServerUrl: envConfigs.dev.APP_SHARE_SERVER,
  },
  stg: {
    apiUrl: envConfigs.stg.APP_API_URL,
    apiPlatformUrl: envConfigs.stg.APP_API_PLATFORM_URL,
    contentServerUrl: envConfigs.stg.APP_CONTENT_SERVER,
    shareServerUrl: envConfigs.stg.APP_SHARE_SERVER,
  },
  prd: {
    apiUrl: envConfigs.prd.APP_API_URL,
    apiPlatformUrl: envConfigs.prd.APP_API_PLATFORM_URL,
    contentServerUrl: envConfigs.prd.APP_CONTENT_SERVER,
    shareServerUrl: envConfigs.prd.APP_SHARE_SERVER,
  },
} as const;

const _localStorage = (function () {
  const _prefix = 'DASH_DEBUG_CONFIG_';

  let _listeners: (() => void)[] = [];

  const _fireListeners = () => {
    _listeners.forEach((fn) => fn());
  };

  function set(values: Record<string, unknown>): void;
  function set(key: string, value: string): void;
  function set(...args: [Record<string, unknown>] | [string, string]) {
    if (args.length === 1) {
      const newConfig = args[0];
      Object.keys(newConfig).forEach((key) => {
        const value = newConfig[key];
        window.localStorage.setItem(_prefix + key, String(value));
      });
    } else {
      const [key, value] = args;
      window.localStorage.setItem(_prefix + key, value);
    }
    _fireListeners();
  }

  return {
    get(key: string) {
      return window.localStorage.getItem(_prefix + key);
    },
    set,
    remove(keys: string | string[]) {
      keys = typeof keys === 'string' ? [keys] : keys;
      keys.forEach((key) => {
        window.localStorage.removeItem(_prefix + key);
      });
      _fireListeners();
    },
    subscribe(fn: () => void) {
      _listeners.push(fn);
      return () => {
        _listeners = _listeners.filter((f) => fn !== f);
      };
    },
  };
})();

export function getLocalOverrides(config: DashboardConfig = ConfigStore.getCurrentState()): Partial<DashboardConfig> {
  const envConfig = getEnvConfig();

  // Get overridden values from localstorage
  const configKeys = Object.keys(config) as (keyof typeof config)[];
  return configKeys.reduce((acc, key) => {
    const value = _localStorage.get(key);
    if (!value) {
      return acc;
    }

    let retValue: string | number | boolean;
    switch (typeof config[key]) {
      case 'boolean':
        retValue = value === 'false' ? false : true;
        break;
      case 'number':
        retValue = +value;
        break;
      case 'string':
      default:
        retValue = value;
        break;
    }

    // Skip values that are the same
    if (key in envConfig) {
      if (retValue === envConfig[key as keyof typeof envConfig]) {
        return acc;
      }
    }

    acc[key] = retValue as never;

    return acc;
  }, {} as Partial<DashboardConfig>);
}

export function useLocalOverrides() {
  const config = ConfigStore.useState((s) => s);

  const [rerender, setRerender] = useState(false);

  // Re-render when value in local storage changes
  useEffect(() => {
    const unsub = _localStorage.subscribe(() => {
      setRerender(!rerender);
    });

    return () => {
      unsub();
    };
  }, []);

  const all = useMemo(() => getLocalOverrides(config), [config]);
  const env = useMemo(() => {
    const { apiPlatformUrl, apiUrl, contentServerUrl, shareServerUrl } = all;
    const ret = { apiPlatformUrl, apiUrl, contentServerUrl, shareServerUrl };
    (['apiPlatformUrl', 'apiUrl', 'contentServerUrl', 'shareServerUrl'] as const).forEach((key) => {
      if (typeof ret[key] === 'undefined') {
        delete ret[key];
      }
    });
    return ret;
  }, [all]);

  return useMemo(() => {
    return {
      all,
      env,
    };
  }, [all, env]);
}

export function setLocalOverride<T extends keyof DashboardConfig>(
  newConfig: Partial<Record<T, DashboardConfig[T]>>,
  storageOnly = false,
) {
  if (storageOnly) {
    return _localStorage.set(newConfig);
  }

  const configKeys = Object.keys(newConfig) as (keyof typeof newConfig)[];
  ConfigStore.update((draft) => {
    configKeys.forEach((key) => {
      draft[key] = newConfig[key] as never;
    });
  });

  // Certain configuration values must immediately propagate to companion-lib
  configKeys.forEach((key) => {
    const value = newConfig[key];
    switch (key) {
      case 'apiUrl':
        setCompanionConfig({
          API_URL: value as DashboardConfig['apiUrl'],
        });
        break;
      case 'contentServerUrl':
        setCompanionConfig({
          CONTENT_SERVER: value as DashboardConfig['contentServerUrl'],
        });
        break;
    }
  });

  return _localStorage.set(newConfig);
}

export function clearLocalOverrides(clearKeys?: (keyof DashboardConfig)[]) {
  const config = ConfigStore.getCurrentState();
  const allKeys = Object.keys(config) as (keyof DashboardConfig)[];
  const _clearKeys = typeof clearKeys === 'undefined' ? allKeys : clearKeys;

  const originalValues = ENV_CONFIGS[import.meta.env.APP_JW_ENV as keyof typeof ENV_CONFIGS];
  _localStorage.remove(_clearKeys);
  ConfigStore.update((draft) => {
    _clearKeys.forEach((key) => {
      draft[key] = originalValues[key as keyof typeof originalValues] as never;
    });
  });
}

export function assertJwEnv(): Env | undefined {
  const configStoreState = ConfigStore.getCurrentState();

  return (Object.keys(ENV_CONFIGS) as (keyof typeof ENV_CONFIGS)[]).find((env) => {
    const config = ENV_CONFIGS[env];
    const configKeys = Object.entries(config) as [keyof typeof config, (typeof config)[keyof typeof config]][];
    const allValuesMatch = configKeys.every(([key, value]) => {
      return value === configStoreState[key];
    });

    if (allValuesMatch) {
      return env;
    }
  });
}

export function setJwEnv(env: Env) {
  const envConfig = ENV_CONFIGS[env];
  setLocalOverride(envConfig);
}

export function getEnvConfig(env: Env | undefined = import.meta.env.APP_JW_ENV) {
  if (!env) {
    throw new Error("Argument 'env' is undefined");
  }

  return { ...ENV_CONFIGS[env] };
}
