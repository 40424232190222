import type { CompanionConfig } from '@jwplayer/companion/config';
import { Store } from '@jwplayer/fe-common/module/store/react';

import { getLocalOverrides } from '/debug/util';

export interface DashboardConfig {
  // JW Platform environment settings
  apiUrl: string;
  apiPlatformUrl: string;
  contentServerUrl: string;
  shareServerUrl: string;
  nrtServerUrl: string;
  imageServerUrl: string;
  companionLogLevels: CompanionConfig['LOG_LEVELS'];
}

/**
 * We use a store for configuration because some values can also affect the UI.
 *
 * Any configuration values in here that get changed will be automatically
 * saved to localStorage when in the dev environment.
 */
export const ConfigStore = new Store<DashboardConfig>({
  apiUrl: import.meta.env.APP_API_URL as string,
  apiPlatformUrl: import.meta.env.APP_API_PLATFORM_URL as string,
  contentServerUrl: import.meta.env.APP_CONTENT_SERVER as string,
  shareServerUrl: import.meta.env.APP_SHARE_SERVER as string,
  nrtServerUrl: import.meta.env.APP_NRT_SERVER as string,
  imageServerUrl: import.meta.env.APP_CONTENT_SERVER as string,
  companionLogLevels:
    import.meta.env.APP_JW_ENV === 'dev' ? ['warn', 'trace', 'log', 'info', 'error', 'debug'] : undefined,
});

if (import.meta.env.APP_JW_ENV === 'dev' || import.meta.env.APP_JW_ENV === 'stg') {
  const configOverrides = getLocalOverrides();

  const configOverridesKeys = Object.keys(configOverrides) as (keyof typeof configOverrides)[];
  if (configOverridesKeys.length) {
    // Ensure overrides are applied to configuration
    ConfigStore.update((draft) => {
      configOverridesKeys.forEach((key) => {
        const value = configOverrides[key];
        if (key in draft) {
          draft[key] = value as never;
        }
      });
    });

    console.warn(
      'You are using local configuration overrides. If you experience any issues, run %o.',
      `window.Dash.clearLocalOverrides();`,
    );
  }
}
